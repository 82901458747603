import React from "react"
import ProductSearch from "./product-search"
import ProductTabs from "./product-tabs"

const ProductPage = () => {
  return (
    <section className="product-page-header-component">
      <div className="container is-padding-top-8">
        <div className="is-margin-bottom-8">
          <ProductSearch />
        </div>
        <div className="is-margin-bottom-10">
          <ProductTabs />
        </div>
      </div>
    </section>
  )
}

export default ProductPage
