import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageBanner from "../components/page-banner"
import ProductPage from "../components/product-page"
import HomeContact from "../components/home-contact"

const Page = ({ data }) => {
  const post = data.wordpressMainPage

  return (
    <Layout>
      <SEO post={post} />
      <PageBanner post={post} />
      <ProductPage />
      <HomeContact />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    wordpressMainPage(name: {eq: "produtos"}) {
      ...MainPageFull
    }
  }
`
